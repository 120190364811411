import React from "react";
import { Helmet } from "react-helmet";

export default function TermsOfServicePage() {
  return (
    <div className="section">
      <Helmet>
        <title>Terms of Service</title>
      </Helmet>
      <div className="container content">
        <h1>Terms of Service</h1>
      </div>
    </div>
  );
}
